// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'antd/lib/grid';
import Table from 'antd/lib/table';
import Button from 'antd/lib/button';
import Tooltip from 'antd/lib/tooltip';
import Modal from 'antd/lib/modal';
import Progress from 'antd/lib/progress';
import Text from 'antd/lib/typography/Text';
import moment from 'moment';
// import copy from 'copy-to-clipboard';
import { withTranslation, WithTranslation } from 'react-i18next';
// eslint-disable-next-line import/no-extraneous-dependencies
import {
    CloseOutlined,
} from '@ant-design/icons';

import getCore from 'cvat-core-wrapper';
import { ActiveInference } from 'reducers/interfaces';
// import UserSelector from './user-selector';

const core = getCore();

const baseURL = core.config.backendAPI.slice(0, -7);

interface Props extends WithTranslation {
    taskInstance: any;
    // registeredUsers: any[];
    onJobUpdate(jobInstance: any): void;
    conditionChecked: () => void;
    loaded: boolean;
    loadActivity: string | null;
    // progress bar of auto annotation
    activeInference: ActiveInference | null;
    cancelAutoAnnotation(): void;
}

class JobListComponent extends React.PureComponent<Props & RouteComponentProps> {
    taskId = '';
    conditions: any = {};

    public constructor(props: Props & RouteComponentProps) {
        super(props);
        const {
            taskInstance,
        } = props;
        this.taskId = taskInstance.id;
        this.checkCondition();
    }

    public componentDidUpdate(prevProps: Props): void {
        if (prevProps !== this.props) {
            const {
                loaded,
                activeInference,
                conditionChecked,
            } = this.props;
            if (!activeInference && prevProps.activeInference) {
                this.checkCondition();
            } else if (loaded) {
                conditionChecked();
                this.checkCondition();
            }
        }
    }

    private async checkCondition(): Promise<void> {
        const conditions = await core.server.request(
            `${baseURL}/tensorflow/train/ready-for-train/${this.taskId}`, {
                method: 'GET',
            },
        );
        this.conditions = conditions;
        super.forceUpdate();
    }

    private renderUploadingAnnotation(): JSX.Element {
        const { t } = this.props;

        return (
            <Row>
                <Col span={22}>
                    <Row>
                        <Col>
                            <Text strong>{t('Upload annotations in progress')}</Text>
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }

    private renderAiAnnotationProgress(): JSX.Element {
        const {
            // taskInstance,
            activeInference,
            cancelAutoAnnotation,
        } = this.props;
        const { t } = this.props;
        // Count number of jobs and performed jobs
        // const numOfJobs = taskInstance.jobs.length;
        // const numOfCompleted = taskInstance.jobs.filter(
        //     (job: any): boolean => job.status === 'completed',
        // ).length;

        /*
        // Progress appearence depends on number of jobs
        let progressColor = null;
        // let progressText = null;
        if (numOfCompleted === numOfJobs) {
            progressColor = 'cvat-task-completed-progress';
            progressText = <Text strong className={progressColor}>{t('Completed')}</Text>;
        } else if (numOfCompleted) {
            progressColor = 'cvat-task-progress-progress';
            progressText = <Text strong className={progressColor}>{t('In Progress')}</Text>;
        } else {
            progressColor = 'cvat-task-pending-progress';
            progressText = <Text strong className={progressColor}>{t('Pending')}</Text>;
        }

        const jobsProgress = numOfCompleted / numOfJobs;
        */

        return (
            <>
                {activeInference && (
                    <Row>
                        <Col span={22}>
                            <Row>
                                <Col>
                                    <Text strong>{t('AI annotation in progress')}</Text>
                                </Col>
                            </Row>
                            <Row justify='space-between'>
                                <Col span={22}>
                                    <Progress
                                        percent={Math.floor(activeInference.progress)}
                                        strokeColor={{
                                            from: '#108ee9',
                                            to: '#87d068',
                                        }}
                                        showInfo={false}
                                        strokeWidth={5}
                                        size='small'
                                    />
                                </Col>
                                <Col span={1} className='close-auto-annotation-icon'>
                                    <Tooltip title='Cancel automatic annotation'>
                                        <CloseOutlined
                                            onClick={() => {
                                                Modal.confirm({
                                                    title: 'You are going to cancel automatic annotation?',
                                                    content: 'Reached progress will be lost. Continue?',
                                                    okType: 'danger',
                                                    onOk() {
                                                        cancelAutoAnnotation();
                                                    },
                                                });
                                            }}
                                            rev=''
                                        />
                                    </Tooltip>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                )}
            </>
        );
    }

    public render(): JSX.Element {
        const { t } = this.props;
        const {
            taskInstance,
            history: {
                push,
            },
            loadActivity,
            activeInference,
        } = this.props;

        const { jobs, id: taskId } = taskInstance;
        let { annot } = this.conditions;
        if (!annot) {
            annot = {};
            annot.count = 0;
        }
        const columns = [{
            title: t('Annotation'),
            dataIndex: 'job',
            key: 'job',
            render: (id: number): JSX.Element => (
                <div>
                    <Button
                        type='link'
                        onClick={(e: React.MouseEvent): void => {
                            e.preventDefault();
                            push(`/tasks/${taskId}/jobs/${id}`);
                        }}
                        disabled={activeInference !== null}
                        href={`/tasks/${taskId}/jobs/${id}`}
                    >
                        {t('View/Edit Annotation')}
                    </Button>
                </div>
            ),
        }, {
            title: t('Frames'),
            dataIndex: 'frames',
            key: 'frames',
            className: 'cvat-text-color',
        }, {
            title: t('# of Annotations'),
            render: (): JSX.Element => (
                <Text>{ annot.count }</Text>
            ),
        }, {
            title: t('Started on'),
            dataIndex: 'started',
            key: 'started',
            className: 'cvat-text-color',
        }];

        // let completed = 0;
        const data = jobs.reduce((acc: any[], job: any) => {
            // if (job.status === 'completed') {
            //     completed++;
            // }

            const created = moment(taskInstance.createdDate);

            acc.push({
                key: job.id,
                job: job.id,
                frames: `${job.startFrame}-${job.stopFrame}`,
                status: `${job.status}`,
                started: `${created.format(t('FORMAT_CREATED'))}`,
                duration: `${moment.duration(moment(moment.now()).diff(created)).humanize()}`,
                assignee: job,
            });

            return acc;
        }, []);

        return (
            <div className='cvat-task-job-list'>
                <Row justify='space-between' align='middle'>
                    <Col>
                        <Text className='cvat-text-color cvat-jobs-header'>
                            {t('Annotations')}
                        </Text>
                    </Col>
                </Row>
                {loadActivity && this.renderUploadingAnnotation()}
                {activeInference && this.renderAiAnnotationProgress()}
                {!loadActivity && !activeInference && (
                    <Table
                        className='cvat-task-jobs-table'
                        rowClassName={() => 'cvat-task-jobs-table-row'}
                        columns={columns}
                        dataSource={data}
                        size='small'
                        pagination={{ position: ['bottomCenter'] }}
                    />
                )}
            </div>
        );
    }
}

export default withRouter(withTranslation()(JobListComponent));
