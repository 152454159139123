// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import { isInteger, checkFilter } from 'cvat-core/src/common';
import { ActionUnion, createAction, ThunkAction } from 'utils/redux';
import {
    Model,
    ModelType,
    ModelFiles,
    ActiveInference,
    ModelStatus,
    ModelsQuery,
    JobsStatus,
    CombinedState,
} from 'reducers/interfaces';
import getCore from 'cvat-core-wrapper';

export const PreinstalledModels: { [modelName: string]: Model } = {
    RCNN: {
        id: 2 ** 32 + 1,
        type: 'preinstalled',
        task_id: null,
        owner: null,
        primary: true,
        // translated by components
        name: 'Fine',
        printName: 'Object detection (Fine)',
        originModelName: 'faster_rcnn_resnet101_coco',
        uploadDate: new Date(),
        updateDate: new Date(),
        labels: [
            'person', 'bicycle', 'car', 'motorcycle', 'airplane', 'bus', 'train', 'truck', 'boat', 'traffic_light', 'fire_hydrant', 'stop_sign', 'parking_meter', 'bench', 'bird', 'cat', 'dog', 'horse', 'sheep', 'cow', 'elephant', 'bear', 'zebra', 'giraffe', 'backpack', 'umbrella', 'handbag', 'tie', 'suitcase', 'frisbee', 'skis', 'snowboard', 'sports_ball', 'kite', 'baseball_bat', 'baseball_glove', 'skateboard', 'surfboard', 'tennis_racket', 'bottle', 'wine_glass', 'cup', 'fork', 'knife', 'spoon', 'bowl', 'banana', 'apple', 'sandwich', 'orange', 'broccoli', 'carrot', 'hot_dog', 'pizza', 'donut', 'cake', 'chair', 'couch', 'potted_plant', 'bed', 'dining_table', 'toilet', 'tv', 'laptop', 'mouse', 'remote', 'keyboard', 'cell_phone', 'microwave', 'oven', 'toaster', 'sink', 'refrigerator', 'book', 'clock', 'vase', 'scissors', 'teddy_bear', 'hair_drier', 'toothbrush',
        ],
        status: ModelStatus.PRE_TRAINED,
    },
    MaskRCNN: {
        id: 2 ** 32 + 2,
        type: 'preinstalled',
        task_id: null,
        owner: null,
        primary: true,
        name: 'Mask RCNN Object Detector',
        printName: 'Segmentation',
        originModelName: 'mask_rcnn_resnet101_atrous_coco',
        uploadDate: new Date(),
        updateDate: new Date(),
        labels: ['BG', 'person', 'bicycle', 'car', 'motorcycle', 'airplane',
            'bus', 'train', 'truck', 'boat', 'traffic light',
            'fire hydrant', 'stop sign', 'parking meter', 'bench', 'bird',
            'cat', 'dog', 'horse', 'sheep', 'cow', 'elephant', 'bear',
            'zebra', 'giraffe', 'backpack', 'umbrella', 'handbag', 'tie',
            'suitcase', 'frisbee', 'skis', 'snowboard', 'sports ball',
            'kite', 'baseball bat', 'baseball glove', 'skateboard',
            'surfboard', 'tennis racket', 'bottle', 'wine glass', 'cup',
            'fork', 'knife', 'spoon', 'bowl', 'banana', 'apple',
            'sandwich', 'orange', 'broccoli', 'carrot', 'hot dog', 'pizza',
            'donut', 'cake', 'chair', 'couch', 'potted plant', 'bed',
            'dining table', 'toilet', 'tv', 'laptop', 'mouse', 'remote',
            'keyboard', 'cell phone', 'microwave', 'oven', 'toaster',
            'sink', 'refrigerator', 'book', 'clock', 'vase', 'scissors',
            'teddy bear', 'hair drier', 'toothbrush',
        ],
        status: ModelStatus.PRE_TRAINED,
    },
    MobileNet: {
        id: 2 ** 32 + 3,
        type: 'preinstalled',
        task_id: null,
        owner: null,
        primary: true,
        // translated by components
        name: 'Fast',
        printName: 'Object detection (Fast)',
        originModelName: 'ssd_mobilenet_v2_coco',
        uploadDate: new Date(),
        updateDate: new Date(),
        labels: [
            'person', 'bicycle', 'car', 'motorcycle', 'airplane', 'bus', 'train', 'truck', 'boat', 'traffic_light', 'fire_hydrant', 'stop_sign', 'parking_meter', 'bench', 'bird', 'cat', 'dog', 'horse', 'sheep', 'cow', 'elephant', 'bear', 'zebra', 'giraffe', 'backpack', 'umbrella', 'handbag', 'tie', 'suitcase', 'frisbee', 'skis', 'snowboard', 'sports_ball', 'kite', 'baseball_bat', 'baseball_glove', 'skateboard', 'surfboard', 'tennis_racket', 'bottle', 'wine_glass', 'cup', 'fork', 'knife', 'spoon', 'bowl', 'banana', 'apple', 'sandwich', 'orange', 'broccoli', 'carrot', 'hot_dog', 'pizza', 'donut', 'cake', 'chair', 'couch', 'potted_plant', 'bed', 'dining_table', 'toilet', 'tv', 'laptop', 'mouse', 'remote', 'keyboard', 'cell_phone', 'microwave', 'oven', 'toaster', 'sink', 'refrigerator', 'book', 'clock', 'vase', 'scissors', 'teddy_bear', 'hair_drier', 'toothbrush',
        ],
        status: ModelStatus.PRE_TRAINED,
    },
};

export enum ModelsActionTypes {
    GET_MODELS = 'GET_MODELS',
    GET_MODELS_SUCCESS = 'GET_MODELS_SUCCESS',
    GET_MODELS_FAILED = 'GET_MODELS_FAILED',
    DELETE_TRAINED_MODEL = 'DELETE_TRAINED_MODEL',
    DELETE_TRAINED_MODEL_SUCCESS = 'DELETE_TRAINED_MODEL_SUCCESS',
    DELETE_TRAINED_MODEL_FAILED = 'DELETE_TRAINED_MODEL_FAILED',
    CANCEL_TRAINING_MODEL = 'CANCEL_TRAINING_MODEL',
    CANCEL_TRAINING_MODEL_SUCCESS = 'CANCEL_TRAINING_MODEL_SUCCESS',
    CANCEL_TRAINING_MODEL_FAILED = 'CANCEL_TRAINING_MODEL_FAILED',
    DELETE_MODEL = 'DELETE_MODEL',
    DELETE_MODEL_SUCCESS = 'DELETE_MODEL_SUCCESS',
    DELETE_MODEL_FAILED = 'DELETE_MODEL_FAILED',
    CREATE_MODEL = 'CREATE_MODEL',
    CREATE_MODEL_SUCCESS = 'CREATE_MODEL_SUCCESS',
    CREATE_MODEL_FAILED = 'CREATE_MODEL_FAILED',
    CREATE_MODEL_STATUS_UPDATED = 'CREATE_MODEL_STATUS_UPDATED',
    UPDATE_MODEL = 'UPDATE_MODEL',
    UPDATE_MODEL_SUCCESS = 'UPDATE_MODEL_SUCCESS',
    UPDATE_MODEL_FAILED = 'UPDATE_MODEL_FAILED',
    GET_MODEL = 'GET_MODEL',
    GET_MODEL_SUCCESS = 'GET_MODEL_SUCCESS',
    GET_MODEL_FAILED = 'GET_MODEL_FAILED',
    FORCE_CHECK_JOBS_STATUS = 'FORCE_CHECK_JOBS_STATUS',
    RESERVE_CHECK_JOBS_STATUS = 'RESERVE_CHECK_JOBS_STATUS',
    CHECK_JOBS_STATUS_SUCCESS = 'CHECK_JOBS_STATUS_SUCCESS',
    CHECK_JOBS_STATUS_FAILED = 'CHECK_JOBS_STATUS_FAILED',
    UPDATE_MODELS_STATUS = 'UPDATE_MODELS_STATUS',
    START_INFERENCE_FAILED = 'START_INFERENCE_FAILED',
    GET_INFERENCE_STATUS_SUCCESS = 'GET_INFERENCE_STATUS_SUCCESS',
    GET_INFERENCE_STATUS_FAILED = 'GET_INFERENCE_STATUS_FAILED',
    FETCH_META_FAILED = 'FETCH_META_FAILED',
    SHOW_RUN_MODEL_DIALOG = 'SHOW_RUN_MODEL_DIALOG',
    CLOSE_RUN_MODEL_DIALOG = 'CLOSE_RUN_MODEL_DIALOG',
    CANCEL_INFERENCE_SUCCESS = 'CANCEL_INFERENCE_SUCCESS',
    CANCEL_INFERENCE_FAILED = 'CANCEL_INFERENCE_FAILED',
    EXPORT_MODEL = 'EXPORT_MODEL',
    EXPORT_MODEL_SUCCESS = 'EXPORT_MODEL_SUCCESS',
    EXPORT_MODEL_FAILED = 'EXPORT_MODEL_FAILED',
}

export const modelsActions = {
    getModels: () => createAction(ModelsActionTypes.GET_MODELS),
    getModelsSuccess: (
        models: Model[], totalCounts: number, query: ModelsQuery | null,
    ) => createAction(
        ModelsActionTypes.GET_MODELS_SUCCESS, { models, totalCounts, query },
    ),
    getModelsFailed: (error: any) => createAction(
        ModelsActionTypes.GET_MODELS_FAILED, { error },
    ),
    deleteTrainedModel: (id: number) => createAction(
        ModelsActionTypes.DELETE_TRAINED_MODEL, {
            id,
        },
    ),
    deleteTrainedModelSuccess: (id: number) => createAction(
        ModelsActionTypes.DELETE_TRAINED_MODEL_SUCCESS, {
            id,
        },
    ),
    deleteTrainedModelFailed: (id: number, error: any) => createAction(
        ModelsActionTypes.DELETE_TRAINED_MODEL_FAILED, {
            error, id,
        },
    ),
    cancelTrainingModel: (id: number) => createAction(
        ModelsActionTypes.CANCEL_TRAINING_MODEL, {
            id,
        },
    ),
    cancelTrainingModelSuccess: (id: number) => createAction(
        ModelsActionTypes.CANCEL_TRAINING_MODEL_SUCCESS, {
            id,
        },
    ),
    cancelTrainingModelFailed: (id: number, error: any) => createAction(
        ModelsActionTypes.CANCEL_TRAINING_MODEL_FAILED, {
            error, id,
        },
    ),
    deleteModelSuccess: (id: number) => createAction(
        ModelsActionTypes.DELETE_MODEL_SUCCESS, {
            id,
        },
    ),
    deleteModelFailed: (id: number, error: any) => createAction(
        ModelsActionTypes.DELETE_MODEL_FAILED, {
            error, id,
        },
    ),
    createModel: () => createAction(ModelsActionTypes.CREATE_MODEL),
    createModelSuccess: () => createAction(ModelsActionTypes.CREATE_MODEL_SUCCESS),
    createModelFailed: (error: any) => createAction(
        ModelsActionTypes.CREATE_MODEL_FAILED, {
            error,
        },
    ),
    createModelUpdateStatus: (status: string) => createAction(
        ModelsActionTypes.CREATE_MODEL_STATUS_UPDATED, {
            status,
        },
    ),
    getModel: () => createAction(ModelsActionTypes.GET_MODEL, {}),
    getModelSuccess: (model: Model) => createAction(ModelsActionTypes.GET_MODEL_SUCCESS, { model }),
    getModelFailed: (error: any) => createAction(ModelsActionTypes.GET_MODEL_FAILED, { error }),
    updateModel: () => createAction(ModelsActionTypes.UPDATE_MODEL, {}),
    updateModelSuccess: (model: any) => createAction(
        ModelsActionTypes.UPDATE_MODEL_SUCCESS, {
            model,
        },
    ),
    updateModelFailed: (error: any, model: any) => createAction(
        ModelsActionTypes.UPDATE_MODEL_FAILED, {
            error,
            model,
        },
    ),
    fetchMetaFailed: (error: any) => createAction(ModelsActionTypes.FETCH_META_FAILED, { error }),
    forceCheckJobsStatus: () => createAction(ModelsActionTypes.FORCE_CHECK_JOBS_STATUS),
    reserveCheckJobsStatus: () => createAction(ModelsActionTypes.RESERVE_CHECK_JOBS_STATUS),
    checkJobsStatusSuccess: (trainings: JobsStatus) => createAction(
        ModelsActionTypes.CHECK_JOBS_STATUS_SUCCESS, { trainings },
    ),
    checkJobsStatusFailed: (error: any) => createAction(
        ModelsActionTypes.CHECK_JOBS_STATUS_FAILED, { error },
    ),
    getInferenceStatusSuccess: (taskID: number, activeInference: ActiveInference, task: any) => createAction(
        ModelsActionTypes.GET_INFERENCE_STATUS_SUCCESS, {
            taskID,
            activeInference,
            task,
        },
    ),
    getInferenceStatusFailed: (task: any, error: any) => createAction(
        ModelsActionTypes.GET_INFERENCE_STATUS_FAILED, {
            task,
            error,
        },
    ),
    startInferenceFailed: (task: any, error: any) => createAction(
        ModelsActionTypes.START_INFERENCE_FAILED, {
            task,
            error,
        },
    ),
    cancelInferenceSuccess: (taskID: number) => createAction(
        ModelsActionTypes.CANCEL_INFERENCE_SUCCESS, {
            taskID,
        },
    ),
    cancelInferenceFaild: (task: any, error: any) => createAction(
        ModelsActionTypes.CANCEL_INFERENCE_FAILED, {
            task,
            error,
        },
    ),
    closeRunModelDialog: () => createAction(ModelsActionTypes.CLOSE_RUN_MODEL_DIALOG),
    exportModel: () => createAction(ModelsActionTypes.EXPORT_MODEL, {}),
    exportModelSuccess: (model: any) => createAction(
        ModelsActionTypes.EXPORT_MODEL_SUCCESS, {
            model,
        },
    ),
    exportModelFailed: (error: any, model: any) => createAction(
        ModelsActionTypes.EXPORT_MODEL_FAILED, {
            error,
            model,
        },
    ),
    showRunModelDialog: (taskInstance: any) => createAction(
        ModelsActionTypes.SHOW_RUN_MODEL_DIALOG, {
            taskInstance,
        },
    ),
};

export type ModelsActions = ActionUnion<typeof modelsActions>;

const core = getCore();
const baseURL = core.config.backendAPI.slice(0, -7);

const trainingStatuses = [
    ModelStatus.PREPARING,
    ModelStatus.QUEUED,
    ModelStatus.PRETRAINING,
    ModelStatus.TRAINING,
    ModelStatus.POSTTRAINING,
    ModelStatus.STOPPING,
];

export function getModelAsync(mid: number | string): ThunkAction {
    return async (dispatch): Promise<void> => {
        try {
            dispatch(modelsActions.getModel());
            const nmid = Number(mid);
            let model: Model | null = null;
            if (nmid <= 2 ** 32) {
                const response = await core.server.request(
                    `${baseURL}/tensorflow/train/models/${nmid}`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    },
                );
                model = {
                    ...response,
                    type: 'trained',
                    printName: response.name,
                };
            } else if (nmid === 2 ** 32 + 1) {
                model = PreinstalledModels.RCNN;
            // } else if (nmid === 2 ** 32 + 2) {
            //     model = PreinstalledModels.MaskRCNN;
            } else if (nmid === 2 ** 32 + 3) {
                model = PreinstalledModels.MobileNet;
            }
            if (model) {
                dispatch(modelsActions.getModelSuccess(model));
            } else {
                dispatch(modelsActions.getModelFailed('Invalid model ID'));
            }
        } catch (error) {
            dispatch(modelsActions.getModelFailed(error));
        }
    };
}

export function checkJobsStatusAsync(query: number[], timeout = 0, force = false): ThunkAction {
    return async (dispatch, getState): Promise<void> => {
        const { checkingStatus } = getState().models;

        if (force) {
            dispatch(modelsActions.forceCheckJobsStatus());
        } else if (checkingStatus) {
            return;
        } else {
            dispatch(modelsActions.reserveCheckJobsStatus());
        }

        await new Promise((_) => setTimeout(_, timeout));

        const state: CombinedState = getState();
        const { trainings } = state.models.activities;
        const { models } = state.models;

        const tidSet = new Set(query);
        if (trainings) {
            Object.keys(trainings).forEach((tid: string) => tidSet.add(Number(tid)));
        }

        try {
            // get status
            const response: JobsStatus = await core.server.request(
                `${baseURL}/tensorflow/train/meta/job_status`, {
                    method: 'GET',
                    params: { tids: [...tidSet] },
                },
            );
            const filteredResponse: JobsStatus = {};
            Object.keys(response).forEach((tid) => {
                const taskId = Number(tid);
                const { mid, status } = response[taskId];
                if (trainingStatuses.includes(status)) {
                    filteredResponse[taskId] = response[taskId];
                }
                const model = models.find((m: Model) => m.id === mid);
                if (model &&
                status !== ModelStatus.UNKNOWN &&
                ![ModelStatus.FAILED,
                    ModelStatus.STOPPED,
                    ModelStatus.FINISHED,
                    ModelStatus.DELETED,
                ].includes(model.status) &&
                model.status !== status) {
                    model.status = status;
                    dispatch(modelsActions.updateModelSuccess(model));
                    dispatch(getModelAsync(mid));
                } else if (!model && status !== ModelStatus.DELETED) {
                    dispatch(getModelAsync(mid));
                }
            });
            dispatch(modelsActions.checkJobsStatusSuccess(filteredResponse));
        } catch (error) {
            dispatch(modelsActions.checkJobsStatusFailed(error));
        }
    };
}

export function getModelsAsync(query: ModelsQuery | null = null): ThunkAction {
    return async (dispatch, getState): Promise<void> => {
        const state: CombinedState = getState();
        const OpenVINO = state.plugins.list.AUTO_ANNOTATION;
        const RCNN = state.plugins.list.TF_ANNOTATION;
        const MaskRCNN = false; // state.plugins.list.TF_SEGMENTATION;
        const { TF_TRAIN } = state.plugins.list;
        const { trainings } = state.models.activities;

        // We need remove all keys with null values from query
        const filteredQuery = { ...query };
        for (const key in filteredQuery) {
            if (filteredQuery[key] === null) {
                delete filteredQuery[key];
            }
        }

        checkFilter(filteredQuery, {
            page: isInteger,
        });

        const searchParams = new URLSearchParams();
        for (const field of ['page']) {
            if (Object.prototype.hasOwnProperty.call(filteredQuery, field)) {
                const value = filteredQuery[field];
                if (value) searchParams.set(field, value.toString());
            }
        }

        dispatch(modelsActions.getModels());
        const models: Model[] = [];
        let totalCounts = 0;

        try {
            if (OpenVINO) {
                const response = await core.server.request(
                    `${baseURL}/auto_annotation/meta/get`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        data: JSON.stringify([]),
                    },
                );

                for (const model of response.models) {
                    models.push({
                        id: model.id,
                        type: 'openvino',
                        task_id: model.task_id,
                        owner: model.owner,
                        primary: model.primary,
                        name: model.name,
                        printName: model.name,
                        originModelName: null,
                        uploadDate: model.uploadDate,
                        updateDate: model.updateDate,
                        labels: [...model.labels],
                        status: ModelStatus.PRE_TRAINED,
                    });
                }
            }
            if (TF_TRAIN) {
                const response = await core.server.request(
                    `${baseURL}/tensorflow/train/models?page_size=5&${searchParams.toString()}`, { method: 'GET' },
                );

                totalCounts = response.total_counts;
                const trainingTasks: number[] = [];
                for (const model of response.models) {
                    models.push({
                        ...model,
                        type: 'trained',
                        printName: model.name,
                    });
                    if (trainingStatuses.includes(model.status) && !(model.task_id in trainings)) {
                        trainingTasks.push(model.task_id);
                    }
                }
                dispatch(checkJobsStatusAsync(trainingTasks, 0, true));
            }
            if (RCNN) {
                models.push(PreinstalledModels.RCNN);
            }
            if (MaskRCNN) {
                models.push(PreinstalledModels.MaskRCNN);
            }
            if (RCNN) {
                models.push(PreinstalledModels.MobileNet);
            }
        } catch (error) {
            dispatch(modelsActions.getModelsFailed(error));
            return;
        }

        dispatch(modelsActions.getModelsSuccess(models, totalCounts, query));
    };
}

export function updateModelAsync(model: Model): ThunkAction {
    return async (dispatch): Promise<void> => {
        try {
            dispatch(modelsActions.updateModel());
            await core.server.request(
                `${baseURL}/tensorflow/train/models/${model.id}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    data: { name: model.name },
                },
            );
            const response = await core.server.request(
                `${baseURL}/tensorflow/train/models/${model.id}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                },
            );
            dispatch(modelsActions.updateModelSuccess(response));
        } catch (error) {
            let response = null;
            try {
                response = await core.server.request(
                    `${baseURL}/tensorflow/train/models/${model.id}`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    },
                );
            } catch (fetchError) {
                dispatch(modelsActions.updateModelFailed(error, model));
                return;
            }
            dispatch(modelsActions.updateModelFailed(error, response));
        }
    };
}

export function deleteTrainedModelAsync(id: number): ThunkAction {
    return async (dispatch): Promise<void> => {
        try {
            await core.server.request(`${baseURL}/tensorflow/train/delete/${id}`, {
                method: 'DELETE',
            });
        } catch (error) {
            dispatch(modelsActions.deleteTrainedModelFailed(id, error));
            return;
        }

        dispatch(modelsActions.deleteTrainedModelSuccess(id));
    };
}

export function cancelTrainingModelAsync(id: number): ThunkAction {
    return async (dispatch): Promise<void> => {
        dispatch(modelsActions.cancelTrainingModel(id));

        try {
            await core.server.request(
                `${baseURL}/tensorflow/train/cancel/task/${id}`, {
                    method: 'GET',
                },
            );
        } catch (error) {
            dispatch(modelsActions.cancelTrainingModelFailed(id, error));
            return;
        }

        dispatch(modelsActions.cancelTrainingModelSuccess(id));
    };
}

export function deleteModelAsync(id: number): ThunkAction {
    return async (dispatch): Promise<void> => {
        try {
            await core.server.request(`${baseURL}/auto_annotation/delete/${id}`, {
                method: 'DELETE',
            });
        } catch (error) {
            dispatch(modelsActions.deleteModelFailed(id, error));
            return;
        }

        dispatch(modelsActions.deleteModelSuccess(id));
    };
}

export function createModelAsync(name: string, files: ModelFiles, global: boolean): ThunkAction {
    return async (dispatch): Promise<void> => {
        async function checkCallback(id: string): Promise<void> {
            try {
                const data = await core.server.request(
                    `${baseURL}/auto_annotation/check/${id}`, {
                        method: 'GET',
                    },
                );

                switch (data.status) {
                    case ModelStatus.FAILED:
                        dispatch(modelsActions.createModelFailed(
                            `Checking request has returned the "${data.status}" status. Message: ${data.error}`,
                        ));
                        break;
                    case ModelStatus.UNKNOWN:
                        dispatch(modelsActions.createModelFailed(
                            `Checking request has returned the "${data.status}" status.`,
                        ));
                        break;
                    case ModelStatus.FINISHED:
                        dispatch(modelsActions.createModelSuccess());
                        break;
                    default:
                        if ('progress' in data) {
                            modelsActions.createModelUpdateStatus(data.progress);
                        }
                        setTimeout(checkCallback.bind(null, id), 1000);
                }
            } catch (error) {
                dispatch(modelsActions.createModelFailed(error));
            }
        }

        dispatch(modelsActions.createModel());
        const data = new FormData();
        data.append('name', name);
        data.append('storage', typeof files.bin === 'string' ? 'shared' : 'local');
        data.append('shared', global.toString());
        Object.keys(files).reduce((acc, key: string): FormData => {
            acc.append(key, files[key]);
            return acc;
        }, data);

        try {
            dispatch(modelsActions.createModelUpdateStatus('Request is beign sent..'));
            const response = await core.server.request(
                `${baseURL}/auto_annotation/create`, {
                    method: 'POST',
                    data,
                    contentType: false,
                    processData: false,
                },
            );

            dispatch(modelsActions.createModelUpdateStatus('Request is being processed..'));
            setTimeout(checkCallback.bind(null, response.id), 1000);
        } catch (error) {
            dispatch(modelsActions.createModelFailed(error));
        }
    };
}

interface InferenceMeta {
    active: boolean;
    taskID: number;
    requestID: string;
    modelType: ModelType;
}

const timers: any = {};

async function timeoutCallback(
    url: string,
    taskID: number,
    modelType: ModelType,
    dispatch: (action: ModelsActions) => void,
): Promise<void> {
    try {
        delete timers[taskID];

        const response = await core.server.request(url, {
            method: 'GET',
        });

        const activeInference: ActiveInference = {
            status: response.status,
            progress: +response.progress || 0,
            error: response.error || response.stderr || '',
            modelType,
        };

        if (activeInference.status === ModelStatus.UNKNOWN) {
            const [task] = await core.tasks.get({ id: taskID });
            dispatch(modelsActions.getInferenceStatusFailed(
                task,
                new Error(
                    `Inference status for the task ${taskID} is unknown.`,
                ),
            ));

            return;
        }

        if (activeInference.status === ModelStatus.FAILED) {
            const [task] = await core.tasks.get({ id: taskID });
            dispatch(modelsActions.getInferenceStatusFailed(
                task,
                new Error(
                    `Inference status for the task ${taskID} is failed. ${activeInference.error}`,
                ),
            ));

            return;
        }

        if (activeInference.status !== ModelStatus.FINISHED) {
            timers[taskID] = setTimeout(
                timeoutCallback.bind(
                    null,
                    url,
                    taskID,
                    modelType,
                    dispatch,
                ), 3000,
            );
            dispatch(modelsActions.getInferenceStatusSuccess(taskID, activeInference, null));
        } else {
            const [task] = await core.tasks.get({ id: taskID });
            dispatch(modelsActions.getInferenceStatusSuccess(taskID, activeInference, task));
        }
    } catch (error) {
        const [task] = await core.tasks.get({ id: taskID });
        dispatch(modelsActions.getInferenceStatusFailed(task, new Error(
            `Server request for the task ${taskID} was failed`,
        )));
    }
}

function subscribe(
    inferenceMeta: InferenceMeta,
    dispatch: (action: ModelsActions) => void,
): void {
    if (!(inferenceMeta.taskID in timers)) {
        let requestURL = `${baseURL}`;
        if (inferenceMeta.modelType === ModelType.OPENVINO) {
            requestURL = `${requestURL}/auto_annotation/check`;
        } else if (inferenceMeta.modelType === ModelType.RCNN) {
            requestURL = `${requestURL}/tensorflow/annotation/check/task`;
        } else if (inferenceMeta.modelType === ModelType.MASK_RCNN) {
            requestURL = `${requestURL}/tensorflow/segmentation/check/task`;
        } else if (inferenceMeta.modelType === ModelType.TRAINED) {
            requestURL = `${requestURL}/tensorflow/train/check/task`;
        }
        requestURL = `${requestURL}/${inferenceMeta.requestID}`;
        timers[inferenceMeta.taskID] = setTimeout(
            timeoutCallback.bind(
                null,
                requestURL,
                inferenceMeta.taskID,
                inferenceMeta.modelType,
                dispatch,
            ),
        );
    }
}

export function getInferenceStatusAsync(tasks: number[]): ThunkAction {
    return async (dispatch, getState): Promise<void> => {
        function parse(response: any, modelType: ModelType): InferenceMeta[] {
            return Object.keys(response).map((key: string): InferenceMeta => ({
                taskID: +key,
                requestID: response[key].rq_id || key,
                active: typeof (response[key].active) === 'undefined' ? ['queued', 'started']
                    .includes(response[key].status.toLowerCase()) : response[key].active,
                modelType,
            }));
        }

        const state: CombinedState = getState();
        const OpenVINO = state.plugins.list.AUTO_ANNOTATION;
        const RCNN = state.plugins.list.TF_ANNOTATION;
        const MaskRCNN = state.plugins.list.TF_SEGMENTATION;
        // TODO: Currentry /tensorflow/train/meta/get is not working...
        // const { TF_TRAIN } = state.plugins.list;
        const TF_TRAIN = false;

        const dispatchCallback = (action: ModelsActions): void => {
            dispatch(action);
        };

        try {
            if (OpenVINO) {
                const response = await core.server.request(
                    `${baseURL}/auto_annotation/meta/get`, {
                        method: 'POST',
                        data: JSON.stringify(tasks),
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    },
                );

                parse(response.run, ModelType.OPENVINO)
                    .filter((inferenceMeta: InferenceMeta): boolean => inferenceMeta.active)
                    .forEach((inferenceMeta: InferenceMeta): void => {
                        subscribe(inferenceMeta, dispatchCallback);
                    });
            }

            if (TF_TRAIN) {
                const response = await core.server.request(
                    `${baseURL}/tensorflow/train/meta/get`, {
                        method: 'POST',
                        data: JSON.stringify(tasks),
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    },
                );

                parse(response.run, ModelType.TRAINED)
                    .filter((inferenceMeta: InferenceMeta): boolean => inferenceMeta.active)
                    .forEach((inferenceMeta: InferenceMeta): void => {
                        subscribe(inferenceMeta, dispatchCallback);
                    });
            }

            if (RCNN) {
                const response = await core.server.request(
                    `${baseURL}/tensorflow/annotation/meta/get`, {
                        method: 'POST',
                        data: JSON.stringify(tasks),
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    },
                );

                parse(response, ModelType.RCNN)
                    .filter((inferenceMeta: InferenceMeta): boolean => inferenceMeta.active)
                    .forEach((inferenceMeta: InferenceMeta): void => {
                        subscribe(inferenceMeta, dispatchCallback);
                    });
            }

            if (MaskRCNN) {
                const response = await core.server.request(
                    `${baseURL}/tensorflow/segmentation/meta/get`, {
                        method: 'POST',
                        data: JSON.stringify(tasks),
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    },
                );

                parse(response, ModelType.MASK_RCNN)
                    .filter((inferenceMeta: InferenceMeta): boolean => inferenceMeta.active)
                    .forEach((inferenceMeta: InferenceMeta): void => {
                        subscribe(inferenceMeta, dispatchCallback);
                    });
            }
        } catch (error) {
            dispatch(modelsActions.fetchMetaFailed(error));
        }
    };
}

export function startInferenceAsync(
    taskInstance: any,
    model: Model,
    mapping: {
        [index: string]: string;
    },
    cleanOut: boolean,
    threshold: number,
): ThunkAction {
    return async (dispatch): Promise<void> => {
        try {
            if (model.id !== null && model.id <= 2 ** 32) {
                await core.server.request(
                    `${baseURL}/tensorflow/annotation/start/${model.id}/${taskInstance.id}`, {
                        method: 'POST',
                        data: JSON.stringify({
                            reset: cleanOut,
                            labels: mapping,
                            threshold,
                        }),
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    },
                );
            } else if (model.type === 'preinstalled') {
                await core.server.request(
                    `${baseURL}/tensorflow/annotation/create/task/${model.id}/${taskInstance.id}`, {
                        method: 'POST',
                        data: JSON.stringify({
                            reset: cleanOut,
                            labels: mapping,
                            threshold,
                        }),
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    },
                );
            } else {
                // 2021.09 contradiction
                // require model.id, but else patter doesn't have model.id
                // if delete auto_annotation from django, delete else completely
                await core.server.request(
                    `${baseURL}/auto_annotation/start/${model.id}/${taskInstance.id}`, {
                        method: 'POST',
                        data: JSON.stringify({
                            reset: cleanOut,
                            labels: mapping,
                            threshold,
                        }),
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    },
                );
            }

            dispatch(getInferenceStatusAsync([taskInstance.id]));
        } catch (error) {
            dispatch(modelsActions.startInferenceFailed(taskInstance, error));
        }
    };
}

export function cancelInferenceAsync(taskID: number): ThunkAction {
    return async (dispatch, getState): Promise<void> => {
        try {
            const inference = getState().models.inferences[taskID];
            if (inference) {
                if (inference.modelType === ModelType.OPENVINO) {
                    await core.server.request(
                        `${baseURL}/auto_annotation/cancel/${taskID}`,
                    );
                } else if (inference.modelType === ModelType.TRAINED) {
                    await core.server.request(
                        `${baseURL}/tensorflow/train/cancel/${taskID}`,
                    );
                } else if (inference.modelType === ModelType.RCNN) {
                    await core.server.request(
                        `${baseURL}/tensorflow/annotation/cancel/task/${taskID}`,
                    );
                } else if (inference.modelType === ModelType.MASK_RCNN) {
                    await core.server.request(
                        `${baseURL}/tensorflow/segmentation/cancel/task/${taskID}`,
                    );
                }

                if (timers[taskID]) {
                    clearTimeout(timers[taskID]);
                    delete timers[taskID];
                }
            }

            dispatch(modelsActions.cancelInferenceSuccess(taskID));
        } catch (error) {
            const [task] = await core.tasks.get({ id: taskID });
            dispatch(modelsActions.cancelInferenceFaild(task, error));
        }
    };
}

async function exportModel(model: Model): Promise<string> {
    return new Promise((resolve, reject) => {
        async function request(): Promise<void> {
            if (model) {
                try {
                    const response = await core.server.request1(
                        `${baseURL}/tensorflow/train/models/${model.id}/export`, {
                            method: 'GET',
                        },
                    );
                    if (response.status === 202) {
                        setTimeout(request, 3000);
                    } else {
                        resolve(`${baseURL}/tensorflow/train/models/${model.id}/export?action=download`);
                    }
                } catch (error) {
                    reject(error);
                }
            } else {
                reject(new Error('Internal Server Error'));
            }
        }
        setTimeout(request);
    });
}

export function exportModelAsync(model: Model): ThunkAction {
    return async (dispatch): Promise<void> => {
        try {
            dispatch(modelsActions.exportModel());
            const url = await exportModel(model);
            const downloadAnchor = (window.document.getElementById('downloadAnchor') as HTMLAnchorElement);
            downloadAnchor.href = url;
            downloadAnchor.click();
        } catch (error) {
            dispatch(modelsActions.exportModelFailed(error, model));
            return;
        }

        dispatch(modelsActions.exportModelSuccess(model));
    };
}
